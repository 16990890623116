// $headerBackgroundColor: #e6e6e6; // was getting booostrap function error so hardcoded the value
$backgroundColor: #e6ecff !important;
$rowHoverBackground: var(--color-accent-lighter) !important;
$rowSelectedBackground: var(--color-accent) !important;
// $sortArrowInactive: #6371cf !important;

$sortArrowActive: #6371cf !important;
$sortArrowInactive: transparent !important;
.tabulator {
  // my css class
  background-color: $backgroundColor;

  .tabulator-header {
      // background-color: $headerBackgroundColor;
      border-bottom: 1px solid #808080 !important;
      background-color: var(--color-primary) !important;
      color: white !important;

      .tabulator-col {
          border-right: 1px solid #808080 !important;
          background: var(--color-primary)!important;

          // &.tabulator-moving {
          //     background: $headerBackgroundColor !important;
          // }
          .tabulator-col-content {
              .tabulator-arrow {
                  display: inline-block;
                  position: absolute;
                  top: 9px;
                  right: 8px;
                  width: 0;
                  height: 0;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-bottom: 6px solid $sortArrowInactive;
                  // border-bottom: 6px solid #6371cf !important;
              }

              .tabulator-header-filter {
                  color: black !important;
                  font-weight: 400 !important;
              }

              .tabulator-header-filter>input {
                  text-align: center !important;
              }

          }

          &.tabulator-sortable {
              &[aria-sort="none"] {
                  .tabulator-col-content .tabulator-arrow {
                      border-top: none;
                      border-bottom: 6px solid $sortArrowInactive;
                  }
              }

              &[aria-sort="asc"] {
                  .tabulator-col-content .tabulator-arrow {
                      border-top: none;
                      border-bottom: 6px solid $sortArrowActive;
                  }
              }

              &[aria-sort="desc"] {
                  .tabulator-col-content .tabulator-arrow {
                      border-top: 6px solid $sortArrowActive;
                      border-bottom: none;
                  }
              }
          }

          &.tabulator-col-group {
              .tabulator-col-group-cols {
                  border-top: 1px solid #808080 !important;
              }
          }
      }
  }

  .tabulator-tableHolder {
      height: auto !important;
  }

  .tabulator-footer {
      // background-color: var(--color-accent) !important;
      background-color: var(--color-primary) !important;

      .tabulator-page {
          color: white !important;
      }

      .tabulator-page.active {
          color: #d00 !important;
      }
      .tabulator-paginator {
        color: white !important;
        font-weight: 400 !important;
    }
    .tabulator-page-size{
        color: black !important;
    }
  }
}

.tabulator-menu i {
  color: var(--color-primary) !important;
}

.tabulator-row {

  &.tabulator-row-even {
      // background-color: $rowAltBackgroundColor; // harded values because getting bootstrap function error
      background-color: var(--color-accent) !important;
  }

  &.tabulator-selectable:hover {
      background-color: $rowHoverBackground;
  }

  &.tabulator-selected {
    background-color:$rowSelectedBackground;

  }

  .tabulator-cell {
      &.tabulator-row-handle {
          .tabulator-row-handle-box {
              .tabulator-row-handle-bar {
                  background: #6371cf !important;
              }
          }
      }
  }
}

.table-controls {
    margin-bottom: 10px;
    padding: 10px 5px 0 5px;
    background: #eee;
    font-size: 14px;
}
.table-controls input, .table-controls select, .table-controls button, .table-controls > span {
    margin: 0 5px 10px 5px;
}

.smarttable-button {
    padding: 5px 10px;
    border: 1px solid #303f9f;
    background: #303f9f;
    background: -webkit-gradient(linear, left top, left bottom, from(#303f9f), to(#303f9f));
    background: linear-gradient(to bottom, #303f9f 0%, #303f9f 100%);
    color: #fff;
    cursor: pointer;
    // font-weight: bold;
}

.cell-editable{
    border-bottom: 1px solid black !important;
}
