/*@import "@angular/material/prebuilt-themes/indigo-pink.css";*/

@import "~bootstrap/dist/css/bootstrap.min.css";
@import '~font-awesome/css/font-awesome';
@import '~jsoneditor/dist/jsoneditor.min.css';


* {
    /* font-family: 'Open Sans', sans-serif; */
    font-family: var(--font-famely);
    font-weight: var(--font-weight);
    font-size: var(--font-size) ;
    
}

/*
* {
    font-family: 'Montserrat', arial, tahoma, verdana, sans-serif;
    font-weight: 500;
}
* {
    font-family: 'Open Sans', arial, tahoma, verdana, sans-serif;
    font-weight: 100;
}
*/
.ace_editor, .ace_editor * {
    font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    }
html,
body {
    min-height: 100%;
    margin: 0;
    /* color: var(--body-color) !important; */
}
.btn-primary {
  color: #fff;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.ace_editor, .ace_editor * {
    font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
}

.aligned-center-btn {
    margin: 10px 24px 10px 24px;
    text-align: center;
}
.checkbox-margin {
  margin: 0 10px;
}

.error-msg {
    color: #f44336;
    margin: 10px 24px 10px 24px;
    max-width: 600px;
    box-sizing: border-box;
    word-break: break-word;
}

.no-data-msg-container {
    width: 100%;
    height: calc(100vh - 250px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.data-table-status-bar {
    height: 100px;
    width: 100%;
    box-sizing: border-box;
    border-style: solid;
    border-color: rgba(0, 0, 0, .12);
    border-width: 0.5px;
    padding: 0px 20px 0px 20px;
}

.status-bar-content {
    align-content: center;
    align-items: center;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    width: 100%;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    box-sizing: border-box;
}

#addiconbutton {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

/* .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    background: #808080 !important;
    opacity: 0.1 !important;
} */

.overlay-backdrop {
    background: #000;
    opacity: 0.1  !important;
}
.fill-remaining-space {
    flex: 1 1 auto !important;
}

.blue-snackbar {
    background: #0d47a1 !important;
}

.green-snackbar {
    background: #007E33 !important;
}

.red-snackbar {
    background: #bb0000 !important;
}

.autocomplete-field {
    /*the container must be positioned relative:*/
    position: relative;
    width: 100%;
    display: inline-block;
}

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    overflow-y: auto;
    height: 150px;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    left: 0;
    right: 0;
    background-color: #fff;
}

.autocomplete>div {
    cursor: pointer;
    padding: 0px !important;
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    color: black;
    border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items div:hover {
    background-color: DodgerBlue;
}

.auto-items-area {
    display: flex;
    align-items: center;
}

.autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
}

@keyframes slide {
    100% {
        right: 0;
    }
}

@-webkit-keyframes slide {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(60%);
    }
}

/* .mat-dialog-container{
    padding: 0px !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
} */




/* Tabulator CSS */
/* //Tabulator styles override */

.tabulator .tabulator-footer .tabulator-page{
    border-color: #dee2e6 !important;
    background: none!important;
 }

 .tabulator-paginator label{
     color:#fff !important;
 }
 mat-paginator {
  /* border-bottom: 1px solid rgb(211, 207, 207); */
  padding: 5px;
}
*:focus {
  outline: none !important;
  border: 0 !important;
}

@keyframes slide-out {
    0% {
        transform: translateX(70%);
    }
    100% {
        transform: translateX(220%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(70%);
    }
    100% {
        -webkit-transform: translateX(180%);
    }
}
@-webkit-keyframes slideInDown {
    0% {
      -webkit-transform:translate3d(0,-100%,0);
      transform:translate3d(0,-100%,0);
      visibility:visible
    }
    to {
      -webkit-transform:translateZ(0);
      transform:translateZ(0)
    }
  }
  @keyframes slideInDown
  {
    0% {
      -webkit-transform:translate3d(0,-250%,0);
      transform:translate3d(0,-250%,0);
      visibility:visible;
    }
      to {
        -webkit-transform:translateZ(0);
        transform:translateZ(0);
      }
  }
  .animate__slideInDown {
    -webkit-animation-name:slideInDown;
    animation-name:slideInDown
  }
.animate__animated {
    -webkit-animation-duration:0.3s;
    animation-duration:0.3s;
    -webkit-animation-fill-mode:both;
    animation-fill-mode:both
  }

.animate__slideInRight {
    position: fixed !important;
    bottom: 0;
    top: 64px;
    right: -100vw;
    width: 100vw;
    height: 100%;
    animation: slide 1.3s forwards;
    -webkit-animation: slide 1.3s forwards;
}

.animate__slideInRight .mat-dialog-container {
    border-radius: 0;
}

.animate__slideOutRight {
    animation: slide-out 1.5s forwards;
    -webkit-animation: slide-out 1.5s forwards;
}

.dialog-header {
    font-size: 24px;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.fill-remaining-space {
    flex: 1 1 auto !important;
}
.rete-background.default {
  background-size: 50px 50px !important;
  /* background-color: #faf9fe !important;
  background-image: linear-gradient(90deg,#eeeefe 1px,transparent 0),linear-gradient(180deg,#eeeefe 1px,transparent 0) !important; */
}
.socket.Input {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px !important;
  width: 20px !important;
  height: 20px !important;
  /* margin: 6px !important; */
  vertical-align: middle;
  z-index: 2;
  box-sizing: border-box;
  background: #e3c000 !important;
}
.socket.Start {
    display: inline-block;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px !important;
  width: 20px !important;
  height: 20px !important;
  /* margin: 6px !important; */
  vertical-align: middle;
  z-index: 2;
  box-sizing: border-box;
    background: orange !important;
}
.socket.Success {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px !important;
  width: 20px !important;
  height: 20px !important;
  margin-bottom: 4px !important;
  vertical-align: middle;
  z-index: 2;
  box-sizing: border-box;
  background: #007E33 !important;
}
.socket.FormsOperation {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 10px !important;
    width: 20px !important;
    height: 20px !important;
    margin-bottom: 4px !important;
    vertical-align: middle;
    z-index: 2;
    box-sizing: border-box;
    background: skyblue !important;
}
.socket.Integration {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 10px !important;
    width: 20px !important;
    height: 20px !important;
    margin-bottom: 4px !important;
    vertical-align: middle;
    z-index: 2;
    box-sizing: border-box;
    background: teal !important;
}
.socket.Error {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px !important;
  width: 20px !important;
  height: 20px !important;
  /* margin: -12px !important; */
  vertical-align: middle;
  z-index: 2;
  box-sizing: border-box;
  background: #bb0000 !important;
}
.socket.Else {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px !important;
  width: 20px !important;
  height: 20px !important;
  /* margin: -12px !important; */
  vertical-align: middle;
  z-index: 2;
  box-sizing: border-box;
  background:  rgb(18, 41, 245) !important;
}
.socket.GeneralInput {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 10px !important;
    width: 20px !important;
    height: 20px !important;
    /* margin: -12px !important; */
    vertical-align: middle;
    z-index: 2;
    box-sizing: border-box;
    background: black !important;
  }
  .socket.Inject {
    border-radius: 10px !important;
    width: 20px !important;
    height: 20px !important;
    background: black !important;
  }
.socket.Then {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px !important;
  width: 20px !important;
  height: 20px !important;
  /* margin: -12px !important; */
  vertical-align: middle;
  z-index: 2;
  box-sizing: border-box;
  background: darkgreen !important;
}

.socket.Approved {
  border-radius: 10px !important;
  width: 20px !important;
  height: 20px !important;
  background: #007E33 !important;
}
.socket.Rejected {
  border-radius: 10px !important;
  width: 20px !important;
  height: 20px !important;
  background: orangered !important;
}
.socket.ReturnToRequestor {
  border-radius: 10px !important;
  width: 20px !important;
  height: 20px !important;
  background: black !important;
}
.socket.NextApprovar {
  border-radius: 10px !important;
  width: 20px !important;
  height: 20px !important;
  background:  yellowgreen !important;
}
.connection.socket-input-success, .connection.socket-output-success .main-path {
  stroke: #007E33 !important;
  /* stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards; */

  /* stroke-dasharray: 20;
  animation: dash 5s linear infinite reverse; */

}
.connection.socket-input-error, .connection.socket-output-error .main-path {
  stroke: #bb0000 !important;
  /* stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards; */
  /* stroke-dasharray: 20;
  animation: dash 5s linear infinite reverse; */

}
.connection.socket-input-then, .connection.socket-output-then .main-path {
  stroke: darkgreen !important;
}
.connection.socket-input-else, .connection.socket-output-else .main-path {
  stroke:rgb(18, 41, 245) !important;;
}
.connection.socket-input-start, .connection.socket-output-start .main-path {
    stroke: orange !important;
    /* stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 5s linear forwards; */
    /* stroke-dasharray: 20;
    animation: dash 5s linear infinite reverse ; */
    /* animation-duration: 5s;
    -moz-animation-duration: 5s;
    -webkit-animation-duration: 5s;
    animation-name: dash;
    -moz-animation-name: dash;
    -webkit-animation-name: dash;
    animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-direction: reverse;
    -moz-animation-direction: reverse;
    -webkit-animation-direction: reverse;
    animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards; */
  }
  .connection.socket-input-formsoperation, .connection.socket-output-formsoperation .main-path {
    stroke: skyblue !important;
  }
.connection .main-path {
    fill: none ;
    stroke: #000 !important;
    stroke-width: 5px !important;
    width: auto !important;
    height: auto !important;

}
.dashedpathanimatoion {
  fill: none;
  stroke-width:0;
}
.animationpath {
  stroke:antiquewhite;
  stroke-width:2.5;
  fill: none;
  stroke-dasharray: 20;
  animation: dash 3s linear infinite reverse both running;
}

@keyframes dash {
  /* to {
    /* stroke-dashoffset: 0;
    stroke-dashoffset: 1000;
  } */
  from {  stroke-dashoffset: 0;  }
   to   {  stroke-dashoffset: 100;  }
}
.blink {
  border: 1px dashed green;
  animation: blink 0.3s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
@keyframes blink { 50% { border:2px dashed #000 ;  }  }
.node-icon {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.node-icon .node-name {
    white-space: nowrap;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.dialog-content {
    margin: 16px;
    text-align: center;
}

.import-button {
    float: right;
    margin-left: 10px;
}

.query-builder {
    text-align: center;
}
.custom-dialog .mat-dialog-container {
    display: block;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    margin: 24px;
    padding-left: 0px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    min-height: inherit;
    max-height: inherit;
}


::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: grey;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
	background: grey;
}
.hot-toast-message {
  word-break: break-word !important;
}
.hot-toast-close-btn {
  opacity: 1 !important;
  background-color: var(--error-color) !important;
}
.help-url {
  float: right;
  margin-top: 15px;
  color: white;
}
.is-open > .is-active {
  z-index: 2;
  position: relative !important;
}
.dialog-container {
  margin: 24px;
}
.dialog-container .mat-form-field {
  width: 100%;
}
.pwd-reset > .hot-toast-close-btn {
    opacity: 1 !important;
    background-color: var(--success-color) !important;

  }
  .error-msg-childCompany {
    color: DodgerBlue;
    margin: 10px 24px 10px 24px;
    max-width: 600px;
    box-sizing: border-box;
    word-break: break-word;
  }