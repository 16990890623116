@use '@angular/material' as mat;

.mat-checkbox-layout {
    white-space: normal !important;
}

@include mat.core();

$mat-brand-primary: (
    50 : #e5f0f8,
    100 : #bedbee,
    200 : #92c3e3,
    300 : #66aad8,
    400 : #4698cf,
    500 : #2586c7,
    600 : #217ec1,
    700 : #1b73ba,
    800 : #1669b3,
    900 : #0d56a6,
    A100 : #d4e7ff,
    A200 : #a1caff,
    A400 : #6eadff,
    A700 : #559fff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$mat-brand-accent: (
    50 : #eaf7fd,
    100 : #caecfa,
    200 : #a7e0f6,
    300 : #84d3f2,
    400 : #69c9f0,
    500 : #4fc0ed,
    600 : #48baeb,
    700 : #3fb2e8,
    800 : #36aae5,
    900 : #269ce0,
    A100 : #ffffff,
    A200 : #e9f6ff,
    A400 : #b6e3ff,
    A700 : #9cd9ff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$forms-app-primary: mat.define-palette($mat-brand-primary);
$forms-app-accent: mat.define-palette($mat-brand-accent, A700);
$forms-app-accent-lighter: mat.define-palette($mat-brand-accent, A200);
$forms-app-theme: mat.define-light-theme($forms-app-primary, $forms-app-accent);
@include mat.all-component-themes($forms-app-theme);
$primary: map-get($forms-app-theme, primary);
$accent: map-get($forms-app-theme, accent);

:root {
    --color-primary: #{mat.get-color-from-palette($forms-app-primary)};
    --color-accent: #{mat.get-color-from-palette($forms-app-accent)};
    --color-accent-lighter: #{mat.get-color-from-palette($forms-app-accent-lighter)};
    --body-color: #212529;
    --branding-color: #4FC0ED;
    --branding-dark-color: #2586c7;
    --color-background: #fafafa;
    --forms-nodes-color: skyblue;
    --general-nodes-color: black;
    --integration-nodes-color: teal;
    --script-nodes-color: #e3c000;
    --success-color: #007E33;
    --warning-color: #FFCC00;
    --error-color: #bb0000;
    --masterdata-color: #006B96;
    --font-famely: 'Open Sans', sans-serif;
    --font-weight: 400;
}

//custom light theme
$custom-light-primary: mat.define-palette(mat.$green-palette, 900, 500, 900);
$custom-light-accent: mat.define-palette(mat.$green-palette, 600, 100, 800);
$custom-light-warn: mat.define-palette(mat.$red-palette, 600);
$custom-light-theme: mat.define-light-theme($custom-light-primary, $custom-light-accent, $custom-light-warn);

.custom-light-theme {
    @include mat.all-component-themes($custom-light-theme);
    --font-famely:"Comic Sans MS";
    --font-size: 18px;
    --color-primary: #{mat.get-color-from-palette($custom-light-primary)};
    --color-accent: #{mat.get-color-from-palette($custom-light-accent)};
    --color-warn: #{mat.get-color-from-palette($custom-light-warn)};
}

//custom dark theme

$custom-light-primary: mat.define-palette(mat.$green-palette, 900, 500, 900);
$custom-light-accent: mat.define-palette(mat.$green-palette, 500, 100, 800);
$custom-light-warn: mat.define-palette(mat.$red-palette, 600);
$custom-light-theme: mat.define-dark-theme($custom-light-primary, $custom-light-accent, $custom-light-warn);

.custom-dark-theme {
    @include mat.all-component-themes($custom-light-theme);
    --font-famely:"Comic Sans MS";
    --font-size: 18px;
    --color-primary: #{mat.get-color-from-palette($custom-light-primary)};
    --color-accent: #{mat.get-color-from-palette($custom-light-accent)};
    --color-warn: #{mat.get-color-from-palette($custom-light-warn)};
}


